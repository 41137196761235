import moment from 'moment';
export default {
  methods: {
    momentFormat(value, arg) {
      return moment(value).format(arg);
    },
    momentTimestampFormat(value, arg) {
      return moment(new Date(value)).format(arg);
    },
    vacuntShiftAppliedCheck(value, id) {
      const arr = [];
      let retString;
      // value.forEach(function(x) {
      //   console.log(x);
      //   if (x.user_id == id) {
      //     console.log('Yes');
      //     retString = 'Yes'
      //   } else {
      //     console.log('no');
      //     retString = 'No'
      //   }
      // });
      for (let i of value) {
        if (i.user_id == id) {
          retString = 'Yes';
          break;
        } else {
          retString = 'No';
        }
      }
      return retString;
    },
  },
  filters: {
    momentFormat(value, arg) {
      return moment(value).format(arg);
    },
    momentTimestampFormat(value, arg) {
      return moment(new Date(value)).format(arg);
    },
  },
};
